<template>
  <Head>
    <Title>[admin] {{ activeLabel }}</Title>
  </Head>
  <main
    v-if="routes"
    class="flex min-h-screen w-full bg-[#FBF7F7]"
  >
    <aside
      class="scroll sticky top-0 h-screen w-72 border-l-2 text-black shadow-md md:bg-[#FBF1F1]"
    >
      <div>
        <div class="w-full">
          <div class="my-4 ml-10 mr-4 flex items-center justify-between">
            <div class="flex gap-4">
              <Icon
                name="user"
                size="md"
              />
              <p class="text-md font-semibold">{{ userStore.displayedName }}</p>
            </div>
            <div
              class="cursor-pointer"
              @click="userStore.logout"
            >
              <Icon
                name="logout"
                size="md"
              />
            </div>
          </div>
        </div>
        <hr />

        <nav class="flex h-full w-full flex-col gap-2 py-2">
          <div
            v-for="item in roleRoutes"
            :key="item.id"
            class="p-auto m-auto w-1/2 w-full"
          >
            <NuxtLink :to="item.to">
              <li
                :key="item.key"
                style="list-style: none"
                class="mx-4 flex gap-4 rounded-md py-2 pl-6"
                :class="
                  activeRoute != item.key
                    ? 'transition-all duration-500 hover:bg-[#F9DBE6]'
                    : 'nuxt-link-active'
                "
              >
                <img
                  v-if="item.img === 'acquiring'"
                  src="~/assets/icons/acquiring.svg"
                  class="w-6"
                />
                <Icon
                  v-if="item.img !== 'acquiring'"
                  :name="item.img"
                  size="md"
                />
                <p>{{ item.label }}</p>
              </li>
            </NuxtLink>
          </div>
        </nav>
      </div>
    </aside>

    <div
      class="mx-auto my-6 flex w-6/12 flex-col gap-6 rounded-md border bg-white p-6 shadow-md md:w-7/12 lg:w-8/12 xl:w-9/12 2xl:w-10/12"
    >
      <slot class="" />
      <!-- </div> -->
    </div>
    <BackToTop />
    <Notifications />
  </main>
</template>
<script setup>
import { useUserStore } from '~/store/user';

const userStore = useUserStore();
const route = useRoute();

const routes = [
  {
    label: 'Настройки',
    key: 'settings',
    to: '/settings',
    roles: ['admin', 'developer'],
    img: 'settings',
  },
  {
    label: 'Новости(не используется)',
    key: 'news',
    to: '/news/1',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'news',
  },
  {
    label: 'Новости',
    key: 'blog',
    to: '/blog/1',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'blog',
  },
  {
    label: 'Вопрос-ответ',
    key: 'faq',
    to: '/faq',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'question',
  },
  {
    label: 'Загрузка файлов',
    key: 'filesUpload',
    to: '/filesUpload/1',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'upload',
  },
  {
    label: 'Пользователи',
    key: 'users',
    to: '/users',
    roles: ['admin', 'developer'],
    img: 'users',
  },
  {
    label: 'Партнеры',
    key: 'partners',
    to: '/partners',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'partners',
  },
  {
    label: 'Отзывы',
    key: 'reviews',
    to: '/reviews',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'reviews',
  },
  {
    label: 'Вакансии',
    key: 'vacancies',
    to: '/vacancies/1',
    roles: ['admin', 'hr', 'developer'],
    img: 'vacancies',
  },
  {
    label: 'Документы',
    key: 'documents',
    to: '/documents',
    roles: ['admin', 'developer'],
    img: 'documentFields',
  },
  {
    label: 'Страницы',
    key: 'pages',
    to: '/pages/1',
    roles: ['admin', 'developer', 'content-manager'],
    img: 'webPage',
  },
  {
    label: 'Отклики',
    key: 'applications',
    to: '/applications/1',
    roles: ['admin', 'hr', 'developer'],
    img: 'svedenie',
  },
  {
    label: 'Жалобы',
    key: 'appeal',
    to: '/appeal/1',
    roles: ['admin', 'developer'],
    img: 'appeal',
  },
  {
    label: 'Обратная связь',
    key: 'feedback',
    to: '/feedback/1',
    roles: ['admin', 'developer'],
    img: 'feedback',
  },
  {
    label: 'Эквайринг',
    key: 'payment',
    to: '/payment',
    roles: ['developer'],
    img: 'acquiring2',
  },
  {
    label: 'Способы оплаты',
    key: 'paymentMethods',
    to: '/paymentMethods',
    roles: ['admin', 'developer'],
    img: 'banknotes',
  },
];

const roleRoutes = computed(() =>
  routes.filter((route) => {
    return route.roles.includes(userStore.role);
  }),
);

const activeRoute = computed(() => route.fullPath.split('/')[1]);
const activeLabel = ref('');

const checkRole = () => {
  const currentRoute = routes.find((route) => {
    return route.key === activeRoute.value;
  });

  if (!currentRoute || !currentRoute.roles.includes(userStore.role)) {
    if (userStore.role === 'hr') navigateTo('/vacancies/1');
    else navigateTo('/news/1');
  }
};

const checkLabel = () => {
  const route = routes.find((route) => {
    return activeRoute.value === route.key;
  });
  return route ? route.label : '';
};

watch(activeRoute, () => {
  checkRole();

  activeLabel.value = checkLabel();
});

onMounted(() => {
  checkRole();
  activeLabel.value = checkLabel();
});
</script>

<style scoped>
.nuxt-link-active {
  background-image: linear-gradient(to right, #4a32fe 0%, #a52fd2 3%, #e9def3 3%, #fadbe6 100%);
  transition: all 0.2s;
}
.nuxt-link-active:hover {
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
.nuxt-link-active:active {
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}
a:hover:not(.nuxt-link-active) {
  background-image: linear-gradient(to right, #4a32fe 0%, #a52fd2 3%, #ede6f3 3%);
  transition: all 0.2s;
}

.scroll {
  overflow-y: scroll;
  overflow-y: overlay;
  scrollbar-color: #e4d7fb transparent;
  scrollbar-width: 6px;
}
.scroll::-webkit-scrollbar {
  width: 6px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 3px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(43, 61, 255, 0.2) -66.25%,
    rgba(255, 64, 154, 0.2) 166.25%
  );
}
</style>
